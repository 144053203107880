export const resourceFragment = gql`
    fragment resourceFragment on ResourceType {
        id
        uuid
        name
        isPersonal
        user {
            id
            firstName
            lastName
        }
        description
        createdAt
    }
`;

export const resourceCategoryFragment = gql`
    fragment resourceCategoryFragment on ResourceCategoryType {
        id
        uuid
        name
        createdAt
    }
`;
