import type {
    ResourceTypeConnection,
    ResourceFilter,
    ResourceCategoryTypeConnection,
    ResourceCategoryFilter,
    ResourceAllocationFilter,
    ResourceType,
} from '~/generated/types';
import { resourceFragment } from '~/graphql/fragments/resources';

export type GetResourceData = {
    resource: ResourceType;
}

export type GetResourceVariables = {
    id: string | number;
};

export const GET_RESOURCE_QUERY = gql`
    query GetResource($id: ID!) {
        resource(pk: $id) {
            ...resourceFragment
        }
    }

    ${resourceFragment}
`;

export type GetResourcesData = {
    resources: ResourceTypeConnection;
    allResources: {
        totalCount: number;
    }
}

export type GetResourcesVariables = {
    filter?: ResourceFilter;
};

export const GET_RESOURCES_QUERY = gql`
    query GetResources($filter: ResourceFilter) {
        resources(filters: $filter) {
            edges {
                node {
                    id
                    name
                }
            }

            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }

            totalCount
        }

        allResources: resources {
            totalCount
        }
    }
`;

export type GetResourceCategoriesData = {
    resourceCategories: ResourceCategoryTypeConnection;
    allResourceCategories: {
        totalCount: number;
    }
}

export type GetResourceCategoriesVariables = {
    filter?: ResourceCategoryFilter;
    resourceFilter?: ResourceFilter;
    allocationFilter?: ResourceAllocationFilter;
    includeAllocations?: boolean;
};

export const GET_RESOURCE_CATEGORIES_QUERY = gql`
    query GetResourceCategories(
        $filter: ResourceCategoryFilter,
        $resourceFilter: ResourceFilter,
        $allocationFilter: ResourceAllocationFilter,
        $includeAllocations: Boolean = false
    ) {
        resourceCategories(filters: $filter) {
            edges {
                node {
                    id
                    uuid
                    name
                    resources(filters: $resourceFilter) {
                        id
                        uuid
                        name
                        description
                        isPersonal
                        user {
                            id
                            lastName
                            firstName
                        }
                        resourceAllocations(filters: $allocationFilter) @include(if: $includeAllocations) {
                            id
                            event {
                                id
                                name
                            }
                        }
                    }
                    createdAt
                }
            }

            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }

            totalCount
        }

        allResourceCategories: resourceCategories {
            totalCount
        }
    }
`;
